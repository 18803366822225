@font-face {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  src: url("https://gist.githubusercontent.com/ngs/6263905/raw/dc2465bba2dfb9c41f6e9ce47ee5c1d455f05f58/Avenir.css");
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  background-color: #ffffff;
  color: #fff;
}

.sidebar {
  overflow: auto;
  color: rgb(119, 119, 119);
  background: linear-gradient(
    rgb(249, 248, 247),
    rgb(251, 250, 248) 46px,
    rgb(251, 251, 249) 120px,
    rgb(248, 247, 245) 35%,
    rgb(249, 248, 246)
  );
  height: 100vh;
}

.sidebar__highlights {
  list-style: "none";
  padding: 0;
}

.highlight__location {
  margin-top: 0.5rem;
  text-align: right;
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.sidebar__highlight {
  padding: 1rem;
  cursor: pointer;
  transition: background 140ms ease-in;
  border-bottom: 1px solid rgb(119, 119, 119);
}

.sidebar__highlight:hover {
  background: rgba(58, 56, 52, 0.08);
}

a {
  color: #d35400;
}

blockquote {
  padding: 0;
  margin: 0;
  quotes: "\201c" "\201d";
}
blockquote:before {
  content: open-quote;
}
blockquote:after {
  content: close-quote;
}
